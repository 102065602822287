document.addEventListener("DOMContentLoaded", () => {

  const dropdownMobileItemHeader = document.querySelectorAll('.dropdown-mobile-header')

  for (item of dropdownMobileItemHeader) {
    const link = item.querySelector('.nav-link');
    item.querySelector('i').addEventListener('click', () => {
      if (item.classList.contains('active')) {
        item.classList.remove('active')
        link.classList.remove('active')
      } else {
        item.classList.add('active')
        link.classList.add('active')
      }
    })
  }

  const sectionHeader = document.querySelector('section#header');

  window.addEventListener("scroll", (event) => {
    lastKnownScrollPosition = window.scrollY;

    if (lastKnownScrollPosition > 105) {
      sectionHeader.classList.add('show-small-logo');
    } else {
      sectionHeader.classList.remove('show-small-logo');
    }
  });
})
