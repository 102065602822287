document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('#color-solutions__color-solutions .filter-container')) {
    let parent = document.querySelector('#color-solutions__color-solutions .filter-container');
    let menuItem = parent.querySelectorAll('.item');


    parent.addEventListener('click', (event) => {
      let target = event.target;

      if (target.classList.contains('item')) {
        for (let i = 0; i < menuItem.length; i++) {
          menuItem[i].classList.remove('active');
        }
        target.classList.add('active');
      }
    });

    let mobileSelector = document.querySelector('#color-solutions__color-solutions .selector-container');
    let mobileArrow = document.querySelector('#color-solutions__color-solutions .arrow-icon');
    let mobileMenu = document.querySelector('#color-solutions__color-solutions .mobile-menu');
    let mobileSelectorValue = document.querySelector('#color-solutions__color-solutions div.selector-container .item')

    let mobileMenuItems = document.querySelectorAll('#color-solutions__color-solutions .item.mobile-select');


    let show = false;

    function showDropdown() {
      if (show) {
        mobileSelector.classList.remove('active');
        mobileArrow.classList.remove('up');
        show = !show;
      } else {
        mobileSelector.classList.add('active');
        mobileArrow.classList.add('up');
        show = !show;
      }
    }

    for (let index = 0; index < mobileMenuItems.length; index++) {
      let element = mobileMenuItems[index];
      element.addEventListener('click', function () {
        mobileSelectorValue.textContent = element.textContent
      })
    }

    mobileMenu.addEventListener('click', (event) => {
      showDropdown()
    });


    const buttonColorShowAll = document.querySelector('#color-solutions__color-solutions .color-show-all')
    const colorItemAll = document.querySelectorAll('#color-solutions__color-solutions .color-item-col')
    if (window.screen.width <= 576) {
      buttonColorShowAll.addEventListener('click', () => {
        colorItemAll[1].style.display = 'flex';
        colorItemAll[2].style.display = 'flex';
        colorItemAll[5].style.display = 'flex';
        buttonColorShowAll.style.display = 'none'
      })
    }

    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth <= 576) {
        buttonColorShowAll.addEventListener('click', () => {
          colorItemAll[1].style.display = 'flex';
          colorItemAll[2].style.display = 'flex';
          colorItemAll[5].style.display = 'flex';
          buttonColorShowAll.style.display = 'none'
        })
      }
    })
  }

})
