document.addEventListener("DOMContentLoaded", () => {

  if (document.querySelector('#projects')) {
    const selectSingle = document.querySelector('.__select.first');
    const selectSingle_title = selectSingle.querySelector('.__select__title.first');
    const selectSingle_labels = selectSingle.querySelectorAll('.__select__label.first');

    selectSingle_title.addEventListener('click', () => {
      if ('active' === selectSingle.getAttribute('data-state')) {
        selectSingle.setAttribute('data-state', '');
      } else {
        selectSingle.setAttribute('data-state', 'active');
      }
    });


    for (const label of selectSingle_labels) {
      label.addEventListener('click', (evt) => {
        selectSingle_title.textContent = evt.target.textContent;
        for (const label of selectSingle_labels) {
          label.classList.remove('selected')
        }
        evt.target.classList.add('selected');
        selectSingle.setAttribute('data-state', '');
      });
    }

    const selectSingleSecond = document.querySelector('.__select.second');
    const selectSingle_titleSecond = selectSingleSecond.querySelector('.__select__title.second');
    const selectSingle_labelsSecond = selectSingleSecond.querySelectorAll('.__select__label.second');

    selectSingle_titleSecond.addEventListener('click', () => {
      if ('active' === selectSingleSecond.getAttribute('data-state')) {
        selectSingleSecond.setAttribute('data-state', '');
      } else {
        selectSingleSecond.setAttribute('data-state', 'active');
      }
    });


    for (const label of selectSingle_labelsSecond) {
      label.addEventListener('click', (evt) => {
        selectSingle_titleSecond.textContent = evt.target.textContent;
        for (const label of selectSingle_labelsSecond) {
          label.classList.remove('selected')
        }
        evt.target.classList.add('selected');
        selectSingleSecond.setAttribute('data-state', '');
      });
    }

    // https://yandex.ru/maps/?rtext=~39.720936%2C47.236909&~39.681483%2C47.251045

    // ymaps.ready(function () {
    //   var myMap = new ymaps.Map('maps-place', {
    //     center: [47.236909, 39.720936],
    //     zoom: 12
    //   }, {
    //     searchControlProvider: 'yandex#search'
    //   }),
    //     MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
    //       '<div class="popover top">' +
    //       '<a class="close" href="#">&times;</a>' +
    //       '<div class="arrow"></div>' +
    //       '<div class="popover-inner">' +
    //       '$[[options.contentLayout observeSize minWidth=235 maxWidth=235 maxHeight=350]]' +
    //       '</div>' +
    //       '</div>'
    //     ),
    //     firstPlace = new ymaps.Placemark([47.251045, 39.681483], {
    //       // hintContent: 'Собственный значок метки',
    //       balloonContent: '  <div class="yandex-baloon"> ' +
    //         ' <div class="baloon-container">' +
    //         '<div class="baloon-title">Ледовая арена</div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-home.svg" alt="">' +
    //         '<div class="item">“Стадион Краснозаменского Черноморского флота”</div> </div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-point.svg" alt="">' +
    //         '<div class="item">г. Севастополь</div> </div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-square.svg" alt="">' +
    //         '<div class="item">120 кв.м.</div></div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-time.svg" alt="">' +
    //         '<div class="item">120 кв.м.</div></div></div></div>'
    //     }, {
    //       iconLayout: 'default#image',
    //       iconImageHref: '/src/assets/contacts/black-point.svg',
    //       iconImageSize: [61, 70],
    //       balloonOffset: [-25, -25],
    //     }),


    //     secondPlace = new ymaps.Placemark([47.233079, 39.714430], {
    //       // hintContent: 'Собственный значок метки',
    //       balloonContent: '  <div class="yandex-baloon"> ' +
    //         ' <div class="baloon-container">' +
    //         '<div class="baloon-title">Ледовая арена</div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-home.svg" alt="">' +
    //         '<div class="item">“Стадион Краснозаменского Черноморского флота”</div> </div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-point.svg" alt="">' +
    //         '<div class="item">г. Севастополь</div> </div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-square.svg" alt="">' +
    //         '<div class="item">120 кв.м.</div></div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-time.svg" alt="">' +
    //         '<div class="item">120 кв.м.</div></div></div></div>'
    //     }, {
    //       iconLayout: 'default#image',
    //       iconImageHref: '/src/assets/contacts/yellow-point.svg',
    //       iconImageSize: [61, 70],
    //       balloonOffset: [-25, -25],
    //     }),
    //     thirdPlace = new ymaps.Placemark([47.244377, 39.783649], {
    //       // hintContent: 'Собственный значок метки',
    //       balloonContent: '  <div class="yandex-baloon"> ' +
    //         ' <div class="baloon-container">' +
    //         '<div class="baloon-title">Ледовая арена</div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-home.svg" alt="">' +
    //         '<div class="item">“Стадион Краснозаменского Черноморского флота”</div> </div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-point.svg" alt="">' +
    //         '<div class="item">г. Севастополь</div> </div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-square.svg" alt="">' +
    //         '<div class="item">120 кв.м.</div></div>' +
    //         '<div class="baloon-desc-item-container"> <img src="/src/assets/projects/baloon-time.svg" alt="">' +
    //         '<div class="item">120 кв.м.</div></div></div></div>'
    //     }, {
    //       iconLayout: 'default#image',
    //       iconImageHref: '/src/assets/contacts/black-point.svg',
    //       iconImageSize: [61, 70],
    //       balloonOffset: [-25, -25],

    //     });
    //   myMap.controls.remove('trafficControl');
    //   myMap.controls.remove('fullscreenControl');
    //   myMap.controls.remove('rulerControl');
    //   myMap.controls.remove('typeSelector');
    //   myMap.controls.remove('geolocationControl');
    //   myMap.controls.remove('searchControl');
    //   firstPlace.options.set('hideIconOnBalloonOpen', false);
    //   secondPlace.options.set('hideIconOnBalloonOpen', false);
    //   thirdPlace.options.set('hideIconOnBalloonOpen', false);

    //   myMap.geoObjects
    //     .add(firstPlace)
    //     .add(secondPlace)
    //     .add(thirdPlace)
    // });

    let urbanArtElements = document.querySelectorAll('.urban-art-place .images-container .item')
    let imgContainer = document.querySelector('.images-container')
    let urbanArtBtnContainer = document.querySelector('.btn-container')
    let urbanArtShowAllBtn = document.querySelector('.urbanArtShowAll')
    if (window.screen.width <= 576) {
      resizeFunc(window.screen.width)
    }


    window.addEventListener('resize', (e) => {
      resizeFunc(e.target.innerWidth)
    })

    function resizeFunc(innerWidth) {
      for(let i = 0; i < urbanArtElements.length; i++) {
        let elem = urbanArtElements[i]
        if (innerWidth <= 576 && i > 7 && !imgContainer.classList.contains('active')) {
          elem.style.display = 'none'
          urbanArtBtnContainer.style.display = 'block';
        } else if (innerWidth > 576) {
          urbanArtBtnContainer.style.display = 'none';
          elem.style.display = 'block'
        } else {
          elem.style.display = 'block'
        }
      }
    }

    function urbanArtShowAll() {
      urbanArtBtnContainer.style.display = 'none';
      imgContainer.classList.add('active')
      for (elem of urbanArtElements) {
        elem.style.display = 'block'
      }
    }
    urbanArtShowAllBtn.addEventListener('click', () => {
      urbanArtShowAll()
    });

    // window.addEventListener('resize', (e) => {
    //   if (e.target.innerWidth <= 576) {
    //     for (elem of urbanArtElements) {
    //       elem.style.display = 'none'
    //       urbanArtBtnContainer.style.display = 'block';
    //     }
    //   } else {
    //     for (elem of urbanArtElements) {
    //       elem.style.display = 'flex'
    //     }
    //     urbanArtBtnContainer.style.display = 'none';
    //   }
    // })

    const disabledPlace = document.querySelector('.map-disabled-place')
    disabledPlace.addEventListener('click', () => {
      disabledPlace.style.zIndex = -1;
    })

  }
});

