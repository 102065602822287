document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('#swiper-project')) {
    if (window.screen.width <= 800) {
      document.querySelector('.swiper-button-next').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" fill="black" fill-opacity="0.1"/><path opacity="0.5" d="M18.0581 24.5581C17.814 24.8021 17.814 25.1979 18.0581 25.4419L22.0355 29.4194C22.2796 29.6635 22.6753 29.6635 22.9194 29.4194C23.1635 29.1753 23.1635 28.7796 22.9194 28.5355L19.3839 25L22.9194 21.4645C23.1635 21.2204 23.1635 20.8247 22.9194 20.5806C22.6753 20.3365 22.2796 20.3365 22.0355 20.5806L18.0581 24.5581ZM31.5 24.375H18.5V25.625H31.5V24.375Z" fill="white"/><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" stroke="white" stroke-width="0.833333"/></svg>';
      document.querySelector('.swiper-button-prev').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" fill="black" fill-opacity="0.1"/><path opacity="0.5" d="M18.0581 24.5581C17.814 24.8021 17.814 25.1979 18.0581 25.4419L22.0355 29.4194C22.2796 29.6635 22.6753 29.6635 22.9194 29.4194C23.1635 29.1753 23.1635 28.7796 22.9194 28.5355L19.3839 25L22.9194 21.4645C23.1635 21.2204 23.1635 20.8247 22.9194 20.5806C22.6753 20.3365 22.2796 20.3365 22.0355 20.5806L18.0581 24.5581ZM31.5 24.375H18.5V25.625H31.5V24.375Z" fill="white"/><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" stroke="white" stroke-width="0.833333"/></svg>';
    }

    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth <= 800) {
        document.querySelector('.swiper-button-next').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" fill="black" fill-opacity="0.1"/><path opacity="0.5" d="M18.0581 24.5581C17.814 24.8021 17.814 25.1979 18.0581 25.4419L22.0355 29.4194C22.2796 29.6635 22.6753 29.6635 22.9194 29.4194C23.1635 29.1753 23.1635 28.7796 22.9194 28.5355L19.3839 25L22.9194 21.4645C23.1635 21.2204 23.1635 20.8247 22.9194 20.5806C22.6753 20.3365 22.2796 20.3365 22.0355 20.5806L18.0581 24.5581ZM31.5 24.375H18.5V25.625H31.5V24.375Z" fill="white"/><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" stroke="white" stroke-width="0.833333"/></svg>';
        document.querySelector('.swiper-button-prev').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" fill="black" fill-opacity="0.1"/><path opacity="0.5" d="M18.0581 24.5581C17.814 24.8021 17.814 25.1979 18.0581 25.4419L22.0355 29.4194C22.2796 29.6635 22.6753 29.6635 22.9194 29.4194C23.1635 29.1753 23.1635 28.7796 22.9194 28.5355L19.3839 25L22.9194 21.4645C23.1635 21.2204 23.1635 20.8247 22.9194 20.5806C22.6753 20.3365 22.2796 20.3365 22.0355 20.5806L18.0581 24.5581ZM31.5 24.375H18.5V25.625H31.5V24.375Z" fill="white"/><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" stroke="white" stroke-width="0.833333"/></svg>';
      } else {
        document.querySelector('.swiper-button-next').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" fill="#191D21" fill-opacity="0.1"/> <path opacity="0.5" d="M17.8912 24.5581C17.6472 24.8021 17.6472 25.1979 17.8912 25.4419L21.8687 29.4194C22.1128 29.6635 22.5085 29.6635 22.7526 29.4194C22.9967 29.1753 22.9967 28.7796 22.7526 28.5355L19.2171 25L22.7526 21.4645C22.9967 21.2204 22.9967 20.8247 22.7526 20.5806C22.5085 20.3365 22.1128 20.3365 21.8687 20.5806L17.8912 24.5581ZM31.6665 24.375H18.3332V25.625H31.6665V24.375Z" fill="#191D21"/><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" stroke="#191D21" stroke-width="0.833333"/></svg>';
        document.querySelector('.swiper-button-prev').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" fill="#191D21" fill-opacity="0.1"/> <path opacity="0.5" d="M17.8912 24.5581C17.6472 24.8021 17.6472 25.1979 17.8912 25.4419L21.8687 29.4194C22.1128 29.6635 22.5085 29.6635 22.7526 29.4194C22.9967 29.1753 22.9967 28.7796 22.7526 28.5355L19.2171 25L22.7526 21.4645C22.9967 21.2204 22.9967 20.8247 22.7526 20.5806C22.5085 20.3365 22.1128 20.3365 21.8687 20.5806L17.8912 24.5581ZM31.6665 24.375H18.3332V25.625H31.6665V24.375Z" fill="#191D21"/><rect x="0.416667" y="0.416667" width="49.1667" height="49.1667" rx="24.5833" stroke="#191D21" stroke-width="0.833333"/></svg>';
      }
    })
  }

});