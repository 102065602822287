document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('#news .__select')) {
    const selectSingle = document.querySelector('#news .__select');
    const selectSingle_title = selectSingle.querySelector('.__select__title');
    const selectSingle_labels = selectSingle.querySelectorAll('.__select__label');

    selectSingle_title.addEventListener('click', () => {
      if ('active' === selectSingle.getAttribute('data-state')) {
        selectSingle.setAttribute('data-state', '');
      } else {
        selectSingle.setAttribute('data-state', 'active');
      }
    });


    for (const label of selectSingle_labels) {
      label.addEventListener('click', (evt) => {
        selectSingle_title.textContent = evt.target.textContent;
        for (const label of selectSingle_labels) {
          label.classList.remove('selected')
        }
        evt.target.classList.add('selected');
        selectSingle.setAttribute('data-state', '');
      });
    }
  }

});