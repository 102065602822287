// (function() {
//     var origOpen = XMLHttpRequest.prototype.open;
//     XMLHttpRequest.prototype.open = function() {
//         console.log('request started!');
//         this.addEventListener('load', function() {
//             console.log('request completed!');
//             console.log(this.readyState); //will always be 4 (ajax is completed successfully)
//             console.log(this.responseText); //whatever the response was
//             console.log(this);
//         });
//         origOpen.apply(this, arguments);
//     };
// })();

document.addEventListener('DOMContentLoaded', function () {
    // lozadInit();
})

// window.lozadInit = () => {
//     let lozad = require('lozad');

//     const observer = lozad('.lozad', {
//         threshold: 0.2,
//         enableAutoReload: true
//     });
//     observer.observe();
// }

const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


function fetchFile(url) {
    fetch(url).then(res => res.blob()).then(file => {
        let tempUrl = URL.createObjectURL(file);
        const aTag = document.createElement("a");
        aTag.href = tempUrl;
        console.log(tempUrl);
        aTag.download = url.replace(/^.*[\\\/]/, '');
        document.body.appendChild(aTag);
        aTag.click();
        downloadBtn.innerText = "Download File";
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
    }).catch(() => {
        alert("Failed to download file!");
        downloadBtn.innerText = "Download File";
    });
}

document.addEventListener('DOMContentLoaded', function () {
    lozadInit();

    let form = document.querySelector('#global__feedback-modal-call form.form-container')
    form.addEventListener('submit', function(e) {
        e.preventDefault()
        let submitAr = {};
        let formArray = Array.from(new FormData(form));
        for (let i = 0; i < formArray.length; i++) {
            let element = formArray[i];
            if(element[1] !== '')
                submitAr[element[0]] = element[1]
        }

        let post = JSON.stringify(submitAr)
        const url = "/local/api/handlers/webformModalHandler.php"
        let xhr = new XMLHttpRequest()
        xhr.open('POST', url, true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
        xhr.send(post);

        xhr.onload = function (res) {
            form.parentNode.querySelector('.form-success').classList.add('active')
            form.classList.add('hide')

            if(xhr.status === 200) {
                form.querySelector('.error').innerHTML = ''
                let result = JSON.parse(xhr.response)
                if(!result.status) {
                    form.querySelector('.error').innerHTML = '<div>'+result.errors[0]+'</div>';
                    captchaInit();

                    form.parentNode.querySelector('.form-success').classList.remove('active')
                    form.classList.remove('hide')
                } else {
                }
            }
        }
    })

    let formPres = document.querySelector('#global__modal-download form.form-container')
    formPres.addEventListener('submit', function(e) {
        e.preventDefault()

        const modalContainer = e.target.parentNode.parentNode;

        let submitAr = {};
        let formArray = Array.from(new FormData(formPres));
        for (let i = 0; i < formArray.length; i++) {
            let element = formArray[i];
            if(element[1] !== '')
                submitAr[element[0]] = element[1]
        }

        let post = JSON.stringify(submitAr)
        const url = "/local/api/handlers/webformPresentationModalHandler.php"
        let xhr = new XMLHttpRequest()
        xhr.open('POST', url, true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
        xhr.send(post);

        xhr.onload = function (res) {
            formPres.parentNode.querySelector('.form-success').classList.add('active')
            formPres.classList.add('hide')

            if(xhr.status === 200) {
                formPres.querySelector('.error').innerHTML = ''
                let result = JSON.parse(xhr.response);

                if(!result.status) {
                    formPres.querySelector('.error').innerHTML = '<div>'+result.errors[0]+'</div>';
                    captchaInit();

                    formPres.parentNode.querySelector('.form-success').classList.remove('active')
                    formPres.classList.remove('hide')
                } else {
                    modalContainer.querySelector('a#download_pres').click();
                }

                modalContainer.querySelector('.modal__close[data-micromodal-close]').addEventListener('click', () => {
                    captchaInit();

                    formPres.querySelector('.error').innerHTML = '';
                    formPres.parentNode.querySelector('.form-success').classList.remove('active');
                    formPres.classList.remove('hide');
                })
            }
        }
    })

    let formFeed = document.querySelectorAll('#global__feedback form.form-container')
    for (let i = 0; i < formFeed.length; i++) {
        let formFeedEl = formFeed[i];
        formFeedEl.addEventListener('submit', function(e) {
            e.preventDefault()
            let submitAr = {};
            let formArray = Array.from(new FormData(formFeedEl));
            for (let i = 0; i < formArray.length; i++) {
                let element = formArray[i];
                if(element[1] !== '')
                    submitAr[element[0]] = element[1]
            }
    
            let post = JSON.stringify(submitAr)
            const url = "/local/api/handlers/webformModalHandler.php"
            let xhr = new XMLHttpRequest()
            xhr.open('POST', url, true)
            xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
            xhr.send(post);
    
            xhr.onload = function (res) {
                formFeedEl.parentNode.querySelector('.form-success').classList.add('active')
                formFeedEl.classList.add('hide')
    
                if(xhr.status === 200) {
                    formFeedEl.parentNode.querySelector('.error').innerHTML = ''
                    let result = JSON.parse(xhr.response)
                    if(!result.status) {
                        formFeedEl.parentNode.querySelector('.error').innerHTML = '<div>'+result.errors[0]+'</div>';
                        captchaInit();
    
                        formFeedEl.parentNode.querySelector('.form-success').classList.remove('active')
                        formFeedEl.classList.remove('hide')
                    }
                }
            }
        })
    }
})

window.lozadInit = () => {
    let lozad = require('lozad');

    const observer = lozad('.lozad', {
        threshold: 0.5,
        enableAutoReload: true
    });
    observer.observe();
}
