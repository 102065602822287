
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#products')) {
    const colspanItem = document.querySelectorAll('.colspan')
    if (window.screen.width <= 576) {
      for (elem of colspanItem) {
        elem.childNodes[1].setAttribute('colspan', '5');
        elem.childNodes[3].setAttribute('colspan', '3');
      }
    }

    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth <= 576) {
        for (elem of colspanItem) {
          elem.childNodes[1].setAttribute('colspan', '5');
          elem.childNodes[3].setAttribute('colspan', '3');
        }
      }
    })
  }
})