document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('.swiper.main-slider')) {
    const mainSliderPageNext = document.querySelector('.slider-page.next')
    const mainSliderPagePrev = document.querySelector('.slider-page.prev')
    const leftNavigate = document.querySelector('.left-navigate')
    const rightNavigate = document.querySelector('.right-navigate')

    mainSliderPagePrev.style.opacity = 0;
    leftNavigate.style.opacity = 0;
    const mainSliderTotalSlides = document.querySelectorAll('.swiper-slide.main-slider').length
    const mainSwiper = new Swiper(".swiper.main-slider", {
      direction: "horizontal",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        slideChange: function () {
          if (mainSwiper.activeIndex === 0) {
            mainSliderPagePrev.innerHTML = '00'
            mainSliderPagePrev.style.opacity = 0;
            leftNavigate.style.opacity = 0;
            mainSliderPageNext.innerHTML = '02'
          } else if ((mainSwiper.activeIndex + 1) != mainSliderTotalSlides) {
            mainSliderPagePrev.innerHTML = '0' + parseInt(mainSwiper.activeIndex)
            mainSliderPagePrev.style.opacity = 1;
            mainSliderPageNext.style.opacity = 1;
            leftNavigate.style.opacity = 1;
            rightNavigate.style.opacity = 1;
            mainSliderPageNext.innerHTML = '0' + parseInt(mainSwiper.activeIndex + 2)
          } else if ((mainSwiper.activeIndex + 1) === mainSliderTotalSlides) {
            mainSliderPagePrev.innerHTML = '0' + parseInt(mainSwiper.activeIndex)
            mainSliderPageNext.innerHTML = '0' + parseInt(mainSwiper.activeIndex + 1)
            mainSliderPageNext.style.opacity = 0;
            rightNavigate.style.opacity = 0;
          }
        },
      },
    });
  }

  const urbanArt = new Swiper(".urban-art", {
    direction: "horizontal",
    navigation: {
      nextEl: ".art-next",
      prevEl: ".art-prev",
    }
  }
  );
  const urbanCatalog = new Swiper(".swiper.catalog", {
    direction: "horizontal",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
  });
  const urbanSolution = new Swiper(".solution", {
    direction: "horizontal",
    navigation: {
      nextEl: ".swiper-button-next.solution-next",
      prevEl: ".swiper-button-prev.solution-prev",
    }
  });
  const mobileSlider = new Swiper(".mobile-slider", {
    direction: "horizontal",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
  });
  const projectSwiper = new Swiper(".sub-swiper", {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2.5,
      },
      768: {
        slidesPerView: 5,
      },
      1440: {
        slidesPerView: 5,
      }
    }
  });
  const projectSwiperSecond = new Swiper(".swiper-project", {
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next.project",
      prevEl: ".swiper-button-prev.project",
    },
    thumbs: {
      swiper: projectSwiper,
    },
  });

  const windowElem = new Swiper(".window-elem", {
    direction: "horizontal",
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
  });

  const whatsIsUrban = new Swiper(".swiper.panels", {
    direction: "horizontal",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
  });

})