const { lockyOn } = require("dom-locky")

document.addEventListener("DOMContentLoaded", () => {
  const modalBox = document.querySelector('.modal-box')
  const modalBoxWhite = document.querySelectorAll('.white-item')
  const grayBackSpace = document.querySelector('.gray-background-space')
  const modalCallContent = document.querySelector('#global__feedback-modal-call')
  const closeModalCallContainer = document.querySelectorAll('.close-modal-call-container')
  const body = document.querySelector('body')
  const openModalCallBtn = document.querySelectorAll('.open-modal-call-btn')
  let modalActive = false;
  let locky;

  function openModalCall() {
    if (modalActive) {
      grayBackSpace.classList.remove('active')
      modalBox.classList.remove('active')
      locky()

      for (item of modalBoxWhite) {
        item.classList.remove('active')
      }
      modalCallContent.classList.remove('active')
      modalActive = !modalActive;

    } else {
      locky = lockyOn(document.getElementById('global__modal-call'));
      grayBackSpace.classList.add('active')
      modalBox.classList.add('active')
      for (item of modalBoxWhite) {
        item.classList.add('active')
      }
      modalCallContent.classList.add('active')
      modalActive = !modalActive;
    }
  }
  for (item of openModalCallBtn) {
    item.addEventListener('click', () => { openModalCall() })
  }

  grayBackSpace.addEventListener('click', () => { openModalCall() })

  for (item of closeModalCallContainer) {
    item.addEventListener('click', () => { openModalCall() })
  }
})