require('./bootstrap');

import '../scss/app.scss';

import { createApp } from 'vue';
import Swiper, { Navigation, Pagination, Autoplay, Thumbs, EffectFade, Scrollbar } from 'swiper';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import AOS from 'aos';
import { Mask, MaskInput, vMaska } from 'maska'
import { lockyOn } from 'dom-locky';

new MaskInput("[data-maska]")

AOS.init({
    once: true,
});

Fancybox.bind('[data-fancybox]', {});
window.lockyOn = lockyOn;
// import Toast, { POSITION } from "vue-toastification";
// import vfmPlugin from 'vue-final-modal';

// Импорт css
// import "vue-toastification/dist/index.css";

// Импорт компонентов
// import testComponent from './components/testComponent';

Swiper.use([Navigation, Pagination, Autoplay, Thumbs, EffectFade, Scrollbar]);
window.Swiper = Swiper;

const app = createApp({});

const toastOptions = {
    // You can set your default options here
    // position: POSITION.TOP_RIGHT
};


// app.use(Toast, toastOptions);
// app.use(vfmPlugin);

// app.component('test-component', testComponent);

require("./helpers");
require("./main");

require('./scripts/headerHamburgerOpen')

require('./scripts/ymaps')
require('./scripts/products')
require('./scripts/index-page')
require('./scripts/header')
require('./scripts/cocoen')
require('./scripts/cocoen-mobile')
require('./scripts/video-section')
require('./scripts/call-modal')
require('./scripts/micromodal')
require('./scripts/micromodal-open')
require('./scripts/color-solution-page')
require('./scripts/news-page')
require('./scripts/project-page')
require('./scripts/swiper-project')
require('./scripts/contacts-page')


require('./scripts/swipers')

// require('./scripts/dom-locky')


// require("suggestions-jquery/dist/js/jquery.suggestions");

// Инициализация tosts
// let toastElList = [].slice.call(document.querySelectorAll('.toast'))
// let toastList = toastElList.map(function (toastEl) {
//   return new bs.Toast(toastEl, {
//     delay: 5000
//   })
// })

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Автозагрузка компонентов
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
// Vue.component('setting-component', settingsComponent)


// const app = new Vue({
//   el: '#umax_webmaster_performers_pro',
// });

app.mount('#umax_webmaster_performers_pro');
