window.addEventListener("DOMContentLoaded", (event) => {
  if (document.querySelector('.meter-0')) {
    const progressCircle = document.querySelectorAll('.meter-0');

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
          if(entry.isIntersecting) {
            progressCircle.forEach((elem, index) => {
              elem.classList.add(`meter-${index + 1}`);
            });
          }
      });
    });
    let el = document.querySelector('.video-down-row');
    observer.observe(el);
  }
});