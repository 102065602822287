const hamburger = document.querySelector(".hamburger");
const navMenu = document.querySelector(".nav-menu");

// const { lockyOn } = require("dom-locky");

// let off = '';

hamburger.addEventListener("click", mobileMenu);

function mobileMenu() {
  hamburger.classList.toggle("active");
  navMenu.classList.toggle("active");

  // document.querySelector("html").style.overflow = "hidden";
  if (hamburger.classList.contains("active")) {
    document.querySelector("html").style.overflow = "hidden";
  } else {
    document.querySelector("html").style.overflow = "auto";
  }
  // document.body.style.overflow = "hidden";

  // off = lockyOn(document.querySelector(".header"));
}

function closeMenu() {
  hamburger.classList.remove("active");
  navMenu.classList.remove("active");

  console.log(24323443423);

  document.querySelector("html").style.overflow = "auto";
  // document.body.style.overflow = "auto";

  // off();
}